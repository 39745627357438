<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="PERFESSIONAL_INFO"
      tooltip-title="PERFESSIONAL_INFO"
      :display-breadcrumb="true"
    />
    <div
      class="min-h-screen bg-bg-color-white pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div class="flex mt-5 lg:gap-3 flex-col lg:flex-row">
        <UiSingleSelect title="Designation" :options="designation" class="flex-1" />
      </div>
      <div class="flex mt-5 lg:gap-3 flex-col lg:flex-row">
        <UiSingleSelect title="Campus" :options="campus" class="flex-1" />
        <InputBox
          type="text"
          name="Qualification"
          title="Qualification"
          label="Qualification"
          placeholder="Msc, Computer Science"
          class="flex-1"
        />
      </div>
      <UiDatePicker title="Join Date" class="flex-1" />
      <button
        class="mt-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
      >
        Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    TitleWrapper,
    UiSingleSelect,
    InputBox,
    UiDatePicker,
  },
  data() {
    return {
      designation: ['Teacher', 'Student', 'HOD'],
      campus: ['ISB', 'LHR', 'FSD'],
    }
  },
  mounted() {
    this.setRightbarData()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent']),
    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'Settings',
        },
        profileInfo: [
          {
            isUploadButton: true,
            imageUrl: this.currentStudent?.profile?.image || null,
          },
        ],
        bargraph: [
          {
            barGraphTitle: this.$t('rightBar.Active Hours'),
            barGraphList: [
              { value: 2, color: '#267C26', tooltip: 'BIO' },
              { value: 12, color: '#267C26', tooltip: 'CHEM' },
              { value: 10, color: '#267C26', tooltip: 'PHY' },
              { value: 18, color: '#267C26', tooltip: 'MATH' },
              { value: 6, color: '#267C26', tooltip: 'ENG' },
              { value: 16, color: '#267C26', tooltip: 'URD' },
              { value: 4, color: '#267C26', tooltip: 'PAK' },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
  },
}
</script>
